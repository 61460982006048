<template>
    <Subview hideShadow :contentPadding="false" :class="['main-content settings', $vuetify.breakpoint.xsOnly && 'pb-6']" customContentPadding="pa-0"
        backgroundColor="transparent" title="Booksy Med dla fizjoterapeutów">
        <v-container fluid :class="['mx-0 pa-0', $vuetify.breakpoint.xsOnly && 'pb-6']" slot="subview-content">
            <v-row class="px-3" wrap>
                <v-col :class="['pa-0 d-flex', $vuetify.breakpoint.xsOnly ? 'mb-2' : 'mr-2']"
                    style="flex-direction:column">
                    <SubviewCard class="flex-grow-1">
                        <v-layout column class="mx-1">
                            <DefaultSubtitle>Dlaczego warto?</DefaultSubtitle>
                            <DefaultLabel>{{
                                    `
                            Moduł Booksy Med, który za chwilę aktywujesz, to specjalistyczne narzędzie
                            stworzone dla fizjoterapeutów. Dzięki współpracy Booksy i Znajdź&nbsp;Gabinet
                            dostaniesz nie tylko dostęp do najpopularniejszej platformy rezerwacyjnej
                            w Polsce i funkcji ułatwiających zarządzanie, ale także intuicyjny i szybki
                            system do prowadzenia dokumentacji medycznej w postaci elektronicznej.
                            `}}
                            </DefaultLabel>
                            <v-layout column class="my-3">
                                <ul>
                                    <li>
                                        <DefaultLabel>Integracja z platformą P1.</DefaultLabel>
                                    </li>
                                    <li>
                                        <DefaultLabel>Dostęp do klasyfikacji ICD-9, ICD-10 i ICF.</DefaultLabel>
                                    </li>
                                    <li>
                                        <DefaultLabel>3 rodzaje kart pacjenta: klasyczna, uroginekologiczna i
                                            pediatryczna.
                                        </DefaultLabel>
                                    </li>
                                    <li>
                                        <DefaultLabel>Zgodność z wymogami Ministerstwa Zdrowia i Krajowej Izby
                                            Fizjoterapeutów.
                                        </DefaultLabel>
                                    </li>
                                    <li>
                                        <DefaultLabel>Bezpieczeństwo danych dzięki tworzeniu kopii zapasowych.
                                        </DefaultLabel>
                                    </li>
                                </ul>
                            </v-layout>
                            <DefaultLabel>Wybierając Booksy Med, wybierasz profesjonalizm, wygodę, oszczędność czasu.
                                Zrób to teraz!</DefaultLabel>
                        </v-layout>
                    </SubviewCard>
                </v-col>
                <v-col :class="['pa-0 d-flex', $vuetify.breakpoint.xsOnly ? 'mt-2' : 'ml-2']"
                    style="flex-direction:column" :cols="$vuetify.breakpoint.xsOnly ? 12 : 5">
                    <SubviewCard class="flex-grow-1">
                        <v-layout column class="mx-1">
                            <DefaultSubtitle>Ile to kosztuje?</DefaultSubtitle>
                            <DefaultLabel class="my-1">Miesięczna opłata za Booksy Med to 61.50 zł brutto.</DefaultLabel>
                            <DefaultLabel class="my-3">Płatność będzie pobierana co miesiąc z karty, dodanej po aktywacji modułu.</DefaultLabel>
                            <v-layout justify-center class="my-3">
                                <div class="fizjo-icon"></div>
                            </v-layout>
                            <DefaultSubtitle class="mt-3">Zaznacz wymagane zgody, aby przejść dalej:</DefaultSubtitle>
                            <BooksyRulesDelegate
                                v-model="checkbox1"
                                :messages="confirmationRulesMessages.statue"
                                @clicked="onClicked"
                                />
                            <v-divider></v-divider>
                            <BooksyRulesDelegate
                                v-model="checkbox2"
                                :messages="confirmationRulesMessages.marketing"
                                @clicked="onClicked"
                            />
                            <DefaultButton class="mt-6 mx-0" primary :loading="loading"
                                :disabled="!checkbox1" @click.prevent="enableMedicalAddon">
                                Aktywuj Booksy Med
                            </DefaultButton>
                        </v-layout>
                    </SubviewCard>
                </v-col>
            </v-row>
        </v-container>
    </Subview>
</template>

<script>
export default {
    data() {
        return {
            checkbox1: false,
            checkbox2: false,
            showRules: false,
            loading: false,
            confirmationRulesMessages: {
                statue: {
                    title: "Akceptuję Regulamin i potwierdzam zapoznanie się z Polityką Prywatności.",
                    description: `Chcę korzystać z serwisu Znajdź Gabinet należącego do Znajdź Gabinet sp. z o.o. z siedzibą w Kolbuszowej, nr KRS 0000787192 ("Znajdź Gabinet") w celu zawarcia i wykonania umowy o korzystanie z serwisu Znajdź Gabinet, umożliwiającego nawiązywanie kontaktu pomiędzy mną a Klientem w celu reklamy, promocji, oferowania i rezerwacji Usług, jak również organizowania działalności mojego punktu usługowego. Tym samym akceptuję warunki świadczenia usług określone w <a href="${process.env.VUE_APP_URL_BACKEND + '/static/documents/Znajdz_Gabinet_Booksy_Med_Regulamin.pdf'}" target="_blank">Regulaminie</a> oraz zawieram z Znajdź Gabinet umowę powierzenia przetwarzania danych osobowych, na zasadach określonych w tym Regulaminie. Potwierdzam także zapoznanie się z  <a href="${process.env.VUE_APP_URL_BACKEND + '/static/documents/Znajdz_Gabinet_Booksy_Med_PP.pdf'}" target="_blank">Polityką Prywatności</a>, w której szczegółowo określone zostały zasady przetwarzania danych osobowych przez Znajdź Gabinet.`,
                    isRequired: "Wymagane"
                },
                marketing: {
                    title: "Chcę otrzymywać informacje o promocjach i&nbsp;usługach Znajdź Gabinet, w tym wskazówki dotyczące prowadzenia biznesu.",
                    description: `Wyrażam zgodę na przekazywanie mi przez Booksy International sp. z o.o. z siedzibą w Warszawie, nr KRS 0000515914  informacji handlowych dotyczących produktów i usług oferowanych przez Znajdź Gabinet sp. z o.o. z siedzibą w Kolbuszowej, za pomocą środków komunikacji elektronicznej oraz na użycie telekomunikacyjnych urządzeń końcowych oraz automatycznych systemów wywołujących, w tym połączeń głosowych i SMS, dla celów marketingu bezpośredniego.`,
                    isRequired: "Opcjonalne"
                },
            }
        }
    },
    components: {
        Subview: () => import("@/components/Subview"),
        SubviewCard: () => import("@/components/cards/SubviewCard"),
        DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
        DefaultLabel: () => import("@/components/text/DefaultLabel"),
        DefaultButton: () => import("@/components/buttons/DefaultButton"),
        BooksyRulesDelegate: () => import("@/components/delegates/BooksyRulesDelegate"),
    },
    methods: {
        onClicked() {
            this.showRules = true;
        },
        enableMedicalAddon() {
            this.loading = true;
            this.$emit("register", this.checkbox2);
        },
    },
}
</script>

<style lang="scss" scoped>
.fizjo-icon {
    font-family: "b-icons";
    font-size: 50px;
    color: white;
    background-color: lightgray;
    border-radius: 50px;
    height: 80px;
    width: 80px;
    line-height: 80px;
    text-align: center;
}

li {
    color: black;
}
</style>